.nossos-servicos-container {
    width: 100%;
    padding: 2rem 5rem 5rem 5rem;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .title-nossos_servicos {
    font-size: 3.5rem;
    margin-bottom: 3rem;
    font-weight: 300;
    /* color: #069; */
  }
  
  .content-nossos_servicos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .left-div-nossos_servicos, .right-div-nossos_servicos {
    flex: 1 1 45%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
  }
  
  .image-nossos_servicos, .video-nossos_servicos {
    width: 100%;
    height: 450px; /* Altura fixa para imagem e vídeo */
    max-width: 100%;
    object-fit: cover; /* Garante que o conteúdo preencha o espaço sem distorção */
    margin-bottom: 0.5rem;
  }
  
  .nome-nossos_servicos {
    font-size: 1.1rem;
    margin-top: 1rem;
  }
  
  /* Ajuste responsivo */
  @media (max-width: 1400px) {
    .image-nossos_servicos, .video-nossos_servicos {
        width: 100%;
        height: 350px; /* Mantém altura fixa */
      }
  }

  @media (max-width: 1300px) {
    .image-nossos_servicos, .video-nossos_servicos {
        width: 100%;
        height: 300px; /* Mantém altura fixa */
        
      }
  }

  @media (max-width: 1100px) {
    .image-nossos_servicos, .video-nossos_servicos {
        width: 100%;
        height: 250px; /* Mantém altura fixa */
      }
  }

  @media (max-width: 768px) {
    .nossos-servicos-container {
      padding: 2rem 1rem 5rem 1rem;
    }

    .content-nossos_servicos {
      flex-direction: column;
    }
  
    .left-div-nossos_servicos, .right-div-nossos_servicos {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  
    .image-nossos_servicos, .video-nossos_servicos {
      width: 100%;
      height: 200px; /* Mantém altura fixa */
    }
  }
  