/* Estilos gerais do Footer */
.footer-container {
    /* padding: 20px 40px; */
    padding: 1rem 5rem;
    background-color: #000; /* Cor de fundo preta */
    color: white;
    min-height: 350px;
    justify-content: center;
    align-items: center;
}

/* Container para os blocos do Footer */
.footer-blocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Permite que as divs quebrem linha se necessário */
    margin-bottom: 20px; /* Espaço abaixo dos blocos para o texto de direitos reservados */
    height: 80%;
    /* background-color: #E1306C; */
    gap: 1rem;
}

/* Estilos dos blocos individuais */
.footer-block {
    padding: 20px 0;
    border-radius: 8px;
    margin: 10px;
    flex: 1;
}

.footer-logo {
    height: 60px;
    margin-bottom: 10px;
}

.block-1 {
    flex: 1.5;
}

.block-2 {
    flex: 1;
}

.block-3 {
    flex: 1;
}

.block-4 {
    flex: 1.5;
}

/* Estilos para os títulos */
.footer-block h3 {
    font-family: 'Poppins', sans-serif; /* Família de fontes moderna */
    font-size: 18px; /* Tamanho da fonte para os títulos */
    font-weight: 300; /* Peso da fonte para uma aparência mais fina */
    color: #00ADEF; /* Cor azul semelhante à da imagem */
    margin-bottom: 15px;
}

/* Estilos para o texto regular e spans */
.footer-block p, .footer-block span {
    font-family: 'Poppins', sans-serif; /* Mesma família de fontes para consistência */
    font-size: 16px; /* Tamanho da fonte para o texto regular */
    font-weight: 300; /* Peso da fonte mais leve */
    color: #FFFFFF; /* Cor branca para o texto */
    margin: 5px 0;
}

/* Estilo para os links e ícones sociais */
.social-link, .contact-item {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Espaço entre os itens de contato */
}

.social-icon {
    font-size: 24px; /* Tamanho dos ícones */
    color: #fff; /* Cor azul para os ícones */
    margin-right: 8px; /* Espaço entre o ícone e o texto */
    transition: color 0.3s ease; /* Suave transição de cor */
}

.social-icon:hover {
    color: #E1306C; /* Cor rosa ao passar o mouse */
}

/* Estilos para a seção de direitos reservados */
.footer-bottom {
    text-align: center;
    padding: 20px 0;
    width: 100%;
    font-family: 'Poppins', sans-serif; /* Fonte para consistência */
    font-size: 14px; /* Tamanho da fonte menor para direitos reservados */
    font-weight: 300; /* Peso mais fino para o texto */
    color: #FFFFFF; /* Cor branca */
}

/* Responsividade para dispositivos menores */
@media (max-width: 768px) {
    .footer-container {
        /* padding: 20px 40px; */
        padding: 1rem 2rem;
    }
    .footer-blocks {
        flex-direction: column; /* Empilha os blocos verticalmente */
    }

    .footer-block {
        width: 100%; /* Cada bloco ocupa 100% da largura */
        margin: 10px 0; /* Margem vertical entre os blocos */
    }

    .footer-logo {
        max-width: auto; /* Ajusta o tamanho da logo em telas menores */
    }
}
