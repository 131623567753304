.containerContato {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0rem 5rem;
    box-sizing: border-box;
    margin-bottom: 50px;
  }

  .containerContato h2{
    font-family: 'Poppins', sans-serif; /* Família de fontes moderna */
    font-size: 2rem; /* Tamanho da fonte para os títulos */
    font-weight: normal; /* Peso da fonte para uma aparência mais fina */
    color: #00ADEF; /* Cor azul semelhante à da imagem */
    margin-bottom: 3rem;
}

.leftDivContato p{
  text-align: justify;
  font-size: 1.5rem;
}

.leftDivContato h1{
  font-size: 3rem;
  font-weight: 100;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0rem;
  margin-bottom: 25px;
}
  
  .leftDivContato, .rightDivContato {
    flex: 1 1 45%;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .leftDivContato {
    /* background-color: #f0f0f0; */
    padding: 2rem 5rem;
  }
  
  .rightDivContato {
    /* background-color: #f9f9f9; */
  }
  
  .formContato {
    display: flex;
    flex-direction: column;
  }
  
  .formGroupContato {
    margin-bottom: 15px;
  }
  
  .formGroupContato label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroupContato input, .formGroupContato textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .formGroupContato textarea {
    height: 100px;
    resize: none; /* Impede o redimensionamento do textarea */
  }
  
  small {
    display: block;
    margin-top: 5px;
    color: #555;
    font-size: 12px;
  }
  
  .error {
    color: red;
    font-size: 14px;
  }
  
  .submitButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  @media (max-width: 879px) {
    .leftDivContato{
      padding:2rem
    }

    .leftDivContato p{
      text-align: justify;
      font-size: 1.5rem;
    }
    
    .leftDivContato h1{
      font-size: 2.5rem;
      font-weight: 100;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0rem;
      margin-bottom: 25px;
    }
  
    .leftDivContato, .rightDivContato {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {

    .containerContato{
      padding:0
    }

    .leftDivContato{
      padding:2rem
    }

    .leftDivContato p{
      text-align: justify;
      font-size: 1.2rem;
    }
    
    .leftDivContato h1{
      font-size: 2rem;
      font-weight: 100;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0rem;
      margin-bottom: 25px;
    }
  
    .leftDivContato, .rightDivContato {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  }
  