/* Estilos gerais do Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  /* padding: 10px 20px; */
  background-color: #000;
  color: white;
  height: 70px;
}

.logomarca img {
  height: 60px; /* Ajuste conforme necessário */
  max-width: 100%;
  object-fit: contain;
}

.navbarMenu {
  display: flex;
  gap: 15px;
}

.navbarMenu a {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
  transition: background-color 0.3s;
}

.navbarMenu a:hover {
  background-color: #555;
  border-radius: 5px;
}

/* Estilos para o botão de menu hamburguer */
.hamburger {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

/* Responsividade: tela pequena */
@media (max-width: 768px) {

  .navbar {
    padding: 1rem 1rem;
  }

  .navbarMenu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    flex-direction: column;
    align-items: center;
    background-color: #000;
    display: none;
  }

  .navbarMenu.active {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .logomarca img {
    height: 50px; /* Reduza o tamanho da logomarca em telas pequenas, se necessário */
  }
}
