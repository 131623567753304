/* Estilos gerais do Header */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Permite que as divs quebrem linha quando necessário */
  padding: 0 5rem;
  height: 300px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Poppins', sans-serif
}

.left-block {
  flex: 1;
  min-width: 300px; /* Define um tamanho mínimo para a div esquerda */
  color: white;
}

.right-block {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; /* Garante que a imagem fique colada na base */
  min-width: 400px; /* Define um tamanho mínimo para a div direita */
  height: 100%; /* Garante que a altura da imagem permaneça consistente */
  padding: 0; /* Remove qualquer padding que possa causar espaçamento indesejado */
}

.right-block img {
  height: 100%; /* Garante que a imagem ocupe toda a altura do header */
  width: auto; /* Mantém a proporção da imagem */
  object-fit: cover;
  border-radius: 8px;
}

/* Responsividade para dispositivos menores */
@media (max-width: 1260px) {
  .header-container {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 20px 0 20px;
      height: auto;
  }

  .left-block, .right-block {
      width: 100%;
  }

  .right-block {
      align-items: flex-end; /* Garante que a imagem fique colada na base */
      height: auto; /* Ajusta a altura da imagem para dispositivos menores */
  }

  .right-block img {
      width: 100%;
      height: auto;
  }
}
